<template>
  
    <v-card>
        <v-form @submit="AccountCheck($v.currentAccount)">
            <v-app-bar dense absolute text class="fixedToolbar" style="z-index: 2;">
                <v-toolbar-title v-bind:class="{'headline grey-text': !drawer, 'padLeft headline grey-text': drawer }">New Account</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon type="submit" v-on:click.prevent="AddAccountCheck($v.currentAccount)">
                    <v-icon color="green">fas fa-check</v-icon>
                </v-btn>
                <v-btn icon @click.native="$router.push({ path: '/dashboard/account' })">
                    <v-icon color="red">fas fa-times</v-icon>
                </v-btn>
            </v-app-bar>

            <v-card-text>

                <v-container grid-list-md class="padContentLess">
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-text-field label="Account Name" v-model="currentAccount.accountName"
                                          :error-messages="accountNameErrors"
                                          @input="$v.currentAccount.accountName.$touch()"
                                          @blur="$v.currentAccount.accountName.$touch()"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-autocomplete :items="types"
                                            v-model="currentAccount.accountType"
                                            label="Account Type"
                                            :error-messages="accountTypeErrors"
                                            @input="$v.currentAccount.accountType.$touch()"
                                            @blur="$v.currentAccount.accountType.$touch()"></v-autocomplete>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field label="Account Description" v-model="currentAccount.accountDescription"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field label="Account Amount" type="number" min="0" v-model="currentAccount.accountAmount"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-autocomplete label="Account Currency"
                                            :items="curriences"
                                            v-model="currentAccount.accountCurrency"
                                            :error-messages="accountCurrencyErrors"
                                            @input="$v.currentAccount.accountCurrency.$touch()"
                                            @blur="$v.currentAccount.accountCurrency.$touch()"
                                            item-text="currencyName"
                                            item-value="currencyShort">
                                <template slot="selection"
                                          slot-scope="data">
                                    <v-chip :input-value="data.selected"
                                            class="chip--select-multi">
                                        {{ data.item.currencyName }}
                                    </v-chip>
                                </template>
                                <template slot="item"
                                          slot-scope="data">

                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.currencyName"></v-list-item-title>
                                            <v-list-item-subtitle v-html="data.item.currencyShort"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                </v-container>

            </v-card-text>
        </v-form>
    </v-card>

</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  
  import { globalFunctions } from '../../javascript/globalFunctions'

  export default {
    mixins: [validationMixin],
    props: ['drawer'],
    data() {
      return {
        sameAccount: false,
        types: ["Bank", "Saving", "Checking", "Cash", "Other"],
        curriences: [],
        currentAccount: {
          id: 0,
          accountName: '',
          accountType: '',
          accountDescription: '',
          accountCurrency: '',
          accountAmount: 0,
          objectState: 'New'
        },
        items: []
      }
    },
    computed: {
      accountNameErrors() {
        const errors = []
        if (!this.$v.currentAccount.accountName.$dirty) return errors
        this.sameAccount && errors.push('An Account already has this Name. Enter a new Name or update Account')
        !this.$v.currentAccount.accountName.required && errors.push('Account Name is required')
        return errors
      },
      accountTypeErrors() {
        const errors = []
        if (!this.$v.currentAccount.accountType.$dirty) return errors
        !this.$v.currentAccount.accountType.required && errors.push('Account Type is required')
        return errors
      },
      //accountDescriptionErrors() {
      //  const errors = []
      //  if (!this.$v.currentAccount.accountDescription.$dirty) return errors
      //  !this.$v.currentAccount.accountDescription.required && errors.push('Account Description is required')
      //  return errors
      //},
      accountCurrencyErrors() {
        const errors = []
        if (!this.$v.currentAccount.accountCurrency.$dirty) return errors
        !this.$v.currentAccount.accountCurrency.required && errors.push('Account Currency is required')
        return errors
      }
    },
    methods: {
      AddAccountCheck: function ($v) {

        $v.$touch(); //Triggers Error Messages

        if (this.currentAccount.accountAmount < 0 || !globalFunctions.isIntOrFloat(this.currentAccount.accountAmount) || this.currentAccount.accountAmount === "") { this.currentAccount.accountAmount = 0 }

        if ($v.accountName.$invalid || $v.accountCurrency.$invalid) {
          return
        }

        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].accountName.toLowerCase() === this.currentAccount.accountName.toLowerCase()) {
            this.sameAccount = true
            return
          }
          if (i === this.items.length - 1) {
            this.sameAccount = false
          }
        }

        this.AddAccount($v);
      },
      AddAccount: function ($v) {
          let newEntry = globalFunctions.stringify(this.currentAccount)

        this.addDialog = false

        newEntry.id = globalFunctions.getNextIdForObject(this.items)

        this.$store.commit('addNewAccountToStore', newEntry)

        this.$router.push({ path: '/dashboard/account' })
      },
      removeDeletedAccountsFromItems: function () {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].objectState === 'Delete') {
            this.items.splice(i, 1)
          }
        }
      },
      getAvailableCurriencies: function () {
       let cur = this.$store.state.Currencies

        for (let i = 0; i < cur.length; i++) {
          if (cur[i].ObjectState !== "Delete") {
            this.curriences.push(cur[i])
          }
        }
      }
    },
    beforeMount() {
     
      this.items = this.$store.state.Accounts

      ///Remove deleted items from items array
      this.removeDeletedAccountsFromItems()

      this.getAvailableCurriencies()
  
    },
    validations: {
      currentAccount: {
        accountName: {
          required
        },
        accountType: {
          required
        },
        //accountDescription: {
        //  required
        //},
        accountCurrency: {
          required
        }
      }
     
    }


  }

</script>
